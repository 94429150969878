import { FC, createContext, useContext } from 'react';
import { AuthenticationWithBaseUrl } from '~/graphql';

interface RootContextProps {
  isDev?: boolean;
  children?: React.ReactNode;
  authentication: AuthenticationWithBaseUrl | null;
}

const RootContext = createContext<RootContextProps | null>(null);

export const useRootContext = () => {
  const context = useContext(RootContext);

  if (!context) {
    throw new Error('Make sure to use the RootContext inside of the provider');
  }

  return context;
};

export const RootContextProvider: FC<RootContextProps> = ({
  children,
  ...props
}) => {
  return <RootContext.Provider value={props}>{children}</RootContext.Provider>;
};
